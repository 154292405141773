const content = {
  eps: [    {
      title: "Ghostwriters",
      streamLink: 'https://distrokid.com/hyperfollow/townes/ghostwriters',
      coverImage: 'https://i.imgur.com/qP0qAn0.jpg',
      tracks: [
        {
          title: "Intro",
          length: '0:41',
        },
        {
          title: "Kidnapped (By Your Love)",
          length: '03:27',
        },
        {
          title: "Get Me Out Of Here",
          length: '02:24',
        },
        {
          title: "Oh No",
          length: '02:55',
        },
        {
          title: "Ghostwriters",
          length: '03:30',
        },
        {
          title: "Streams",
          length: '02:46',
        },
        {
          title: "Shadows",
          length: '02:39',
        },
        {
          title: "Outro",
          length: '0:40',
        },
      ],
      credits: [
        'Released November 10, 2023',
        'Composed and performed by Matt Radich',
        'Drums performed by Liam Dwyre',
        'Produced by Josh Hart',
        'Recorded at Audio Valley Recording Studios',
        'Cover art photographed by Felix Zeyun',
         ],
    },
    {
      title: "Oh No",
      streamLink: 'https://distrokid.com/hyperfollow/townes/oh-no3',
      coverImage: 'https://i.imgur.com/tycUIt7.jpg',
      tracks: [
        {
          title: "Oh No",
          length: '02:54',
        },
      ],
      credits: [
        'Released October 13, 2023',
        'Composed and performed by Matt Radich',
        'Drums performed by Liam Dwyre',
        'Produced by Josh Hart',
        'Recorded at Audio Valley Recording Studios',
        'Cover art photographed by Felix Zeyun',
      ],
    },
     {
      title: "Shadows",
      streamLink: 'https://bit.ly/townes2023',
      coverImage: 'https://i.imgur.com/VL9i0bs.jpg',
      tracks: [
        {
          title: "Shadows",
          length: '02:38',
        },
      ],
      credits: [
        'Released September 8, 2023',
        'Composed and performed by Matt Radich',
        'Drums performed by Liam Dwyre',
        'Produced by Josh Hart',
        'Recorded at Audio Valley Recording Studios',
        'Cover art photographed by Felix Zeyun',
      ],
    },
     {
      title: "Next To Me Anyways",
      streamLink: 'https://open.spotify.com/track/2JuObEmInh5hUCLRxSEWmZ?si=a295293e2b3546a0',
      coverImage: 'https://i.imgur.com/3JjHV40.jpg',
      tracks: [
        {
          title: "Next To Me Anyways",
          length: '04:24',
        },
      ],
      credits: [
        'Released November 26, 2021',
        'Composed and performed by Matt Radich',
        'Drums performed by Liam Dwyre',
        'Produced by Josh Hart',
        'Recorded at Audio Valley Recording Studios',
        'Cover art photographed by Felix  Zeyun',
      ],
    },

     {
      title: "Maury Povich",
      streamLink: 'https://open.spotify.com/track/5aFLblXuZuye5g0laEVCQN?si=574ea9b2d82640f3',
      coverImage: 'https://i.imgur.com/u54XOBE.jpg',
      tracks: [
        {
          title: "Maury Povich",
          length: '02:37',
        },
      ],
      credits: [
        'Released August 20, 2021',
        'Composed and performed by Matt Radich',
        'Drums performed by Liam Dwyre',
        'Produced by Josh Hart',
        'Recorded at Audio Valley Recording Studios',
        'Cover art photographed by Felix W Zeyun',
      ],
    },
    {
      title: "Same Old Thing",
      streamLink: 'https://open.spotify.com/track/44uMGJSnPOJhazm1Z9MSPB?si=08f9a1044c3d45ae',
      coverImage: 'https://i.imgur.com/cmlPsL7.jpg',
      tracks: [
        {
          title: "Same Old Thing",
          length: '02:27',
        },
      ],
      credits: [
        'Released July 16, 2021',
        'Composed and performed by Matt Radich',
        'Drums performed by Liam Dwyre',
        'Produced by Josh Hart',
        'Recorded at Audio Valley Recording Studios',
        'Cover art photographed by Felix W Zeyun',
      ],
    },
     {
      title: "Tell Me What I'm Living For",
      streamLink: 'https://open.spotify.com/track/41zwqiwry2yfuuHD4WWlgu?si=f27cb6d477d64aac',
      coverImage: 'https://i.imgur.com/ONh0WtX.jpg',
      tracks: [
        {
          title: "Tell Me What I'm Living For",
          length: '03:41',
        },
      ],
      credits: [
        'Released February 26, 2021',
        'Composed and performed by Matt Radich',
        'Addition vocals performed by Jessica Eblie',
        'Drums performed by Liam Dwyre',
        'Produced by Josh Hart',
        'Recorded at Audio Valley Recording Studios',
        'Cover art photographed by Felix W Zeyun',
      ],
    },
    {
      title: 'Overnight Train',
      streamLink: 'https://open.spotify.com/track/3zpTELICKUWpir5YotdXqx?si=eb26d41df5f04573',
      coverImage: 'https://i.imgur.com/5Hccda3.jpg',
      tracks: [
        {
          title: "Overnight Train",
          length: '04:25',
        },
      ],
      credits: [
        'Released December 11, 2020',
        'Composed and performed by Matt Radich',
        'Drums performed by Liam Dwyre',
        'Produced by Josh Hart',
        'Recorded at Audio Valley Recording Studios',
        'Cover art photographed by Felix W Zeyun',
      ],
    },
    {
      title: 'Tag Along',
      streamLink: 'https://open.spotify.com/track/11cZcEGW3gQmszdofokSNm?si=1addc2de134944e1',
      coverImage: 'https://i.imgur.com/teQ4lN9.jpg',
      tracks: [
        {
          title: "Tag Along",
          length: '03:42',
        },
      ],
      credits: [
        'Released August 28, 2020',
        'Composed and performed by Matt Radich',
        'Drums performed by Liam Dwyre',
        'Produced by Josh Hart',
        'Additional production by William Lepine',
        'Recorded at Audio Valley Recording Studios',
        'Cover art photographed by Felix W Zeyun',
      ],
    },
    {
      title: 'I Don\'t\ Know If I\'\ll Make It Anymore',
      streamLink: 'https://open.spotify.com/track/4Zn1ZrV5TZ10CvoTlA0vui?si=3f1c8f362a8a4a0d',
      coverImage: 'https://i.imgur.com/XyBPNBh.jpg',
      tracks: [
        {
          title: "I Don\'t\ Know If I\'\ll Make It Anymore",
          length: '04:19',
        },
      ],
      credits: [
        'Released June 26, 2020',
        'Composed and performed by Matt Radich',
        'Drums performed by Liam Dwyre',
        'Produced by Josh Hart',
        'Additional production by William Lepine',
        'Recorded at Audio Valley Recording Studios',
        'Cover art photographed by Felix W Zeyun',
      ]
    }
  ]
};
   
export default content;
