import React from 'react';
import Album from "../components/Album";
import Layout from '../components/Layout';

import content from '../content/music';

const MusicPage = () => (
  <Layout>
    <div>
      {content.eps.map(ep => <Album {...ep} key={ep.title} />)}
    </div>
  </Layout>
);

export default MusicPage;
