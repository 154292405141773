import React from 'react';

import "./styles/album.css";


const Album = props => {
    const { title, coverImage, tracks, credits, streamLink } = props;
  
    return (
      <div className="album-wrapper ">
        <img src={coverImage} className="album-image" alt={`${title} cover`}/>
        <div className="album-info">
          <div className="album-header">
            <h2>{title}</h2>
            <a className="stream-link" href={streamLink}>
              <p>
                LISTEN
              </p>
            </a>
          </div>
          <ul>
            {tracks.map(track => (
              <li key={track.title}>
                {track.title} <span className="track-length">{track.length}</span>
              </li>
            ))}
          </ul>
          <div className="album-credits-wrapper">
            {credits.map(credit => <p key={credit}>{credit}</p>)}
          </div>
        </div>
      </div>
    );
  };

export default Album
